<template>
	<div class="venue">
		<SubHeader title="Venues" subtitle="ベニュー" />

		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="table-responsive">
						<table class="table">
							<thead class="thead-dark">
								<tr>
									<th scope="col" class="text-nowrap">Venue</th>
									<th scope="col" class="text-nowrap">TEL/ADDRESS</th>
									<th scope="col" class="text-nowrap">喫煙/禁煙</th>
									<th scope="col" class="text-nowrap">DARTS LIVE</th>
									<th scope="col" class="text-nowrap">DARTS FAN</th>
									<th scope="col" class="text-nowrap">Link</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row" class="text-nowrap">BALABUSHKA</th>
									<td class="text-nowrap">
										06-6213-5056
										<br />〒542-0086&nbsp;大阪市中央区西心斎橋2-9-5
										日宝三ツ寺会館 4F
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/ZaU6PNgMPHwuamkeA"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/c574c698cb025ec058d385ea46352d8f"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/00413.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap"></td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">LEAFLET</th>
									<td class="text-nowrap">
										06-7504-9689
										<br />〒536-0016&nbsp;大阪市城東区蒲生1-5-8 ラ・フォーレ 1F
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/9puv2xcB13DuUTgk6"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking rules-caution">
										<i class="fas fa-smoking-ban mr-1"></i
										>禁煙<br />（店外灰皿アリ）
									</td>
									<td class="text-nowrap"></td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/04224.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a
													href="https://www.facebook.com/dartsbarleaflet/"
													target="blank"
												>
													<i class="fab fa-facebook"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">Harrow's</th>
									<td class="text-nowrap">
										06-6243-1567
										<br />〒542-0083&nbsp;大阪市中央区東心斎橋1-8-14
										オープナテビル3F
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/95CLo79g6mnURLHQA"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/8a1a9ec27948fd535f9f3321c1147265"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/01118.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a
													href="https://www.facebook.com/harrows.osaka.7"
													target="blank"
												>
													<i class="fab fa-facebook"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">Darts Bar BULL</th>
									<td class="text-nowrap">
										06-6213-2316
										<br />〒542-0086&nbsp;大阪市中央区西心斎橋2-8-3
										ラフィーヌ西心斎橋ビル B1F
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/8MFntxJGHboJ4r2A8"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/99a15627f90dcac5a3f63593b5358cc4"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/01121.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a
													href="http://www.netlaputa.ne.jp/~bar-bull/index.htm"
													target="blank"
												>
													<i class="fas fa-home"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">わんすもあー</th>
									<td class="text-nowrap">
										06-4804-1011
										<br />〒553-0007&nbsp;大阪市福島区大開1-20-5
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/qY6xZjWQosULNuww7"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/57f2ab80351f2fa0a3f63593b5358cc4"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/00414.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a
													href="https://www.facebook.com/wancemore.nodahanshin/"
													target="blank"
												>
													<i class="fab fa-facebook"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">Public Bar Stinger</th>
									<td class="text-nowrap">
										06-6195-2855
										<br />〒532-0011&nbsp;大阪市淀川区西中島3-17-3 丸橋ビル5F
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/GyrwRArUkbNwiJhE6"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap"></td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/04527.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a
													href="https://www.facebook.com/Public-Bar-Stinger-538519886272536/"
													target="blank"
												>
													<i class="fab fa-facebook"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">Darts Bar JADE</th>
									<td class="text-nowrap">
										06-6316-0640
										<br />〒530-0027&nbsp;大阪市北区堂山町6-11
										そわぁーる堂山ビル 3F
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/wEEGvVntjdJjbm1Z7"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/65b484f3b7c6c8150d9b047a20a7ba1e"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/01535.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a
													href="https://twitter.com/jade_honten"
													target="blank"
												>
													<i class="fab fa-twitter"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">max OSAKA・心斎橋</th>
									<td class="text-nowrap">
										06-6213-6130
										<br />〒542-0083&nbsp;大阪市中央区東心斎橋2-1-22
										エクセル千年町4F B号室
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/iFH34SjJYsPNewop7"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/55735eda3a2dc3e9fec1ae84bb28bd87"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/00322.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a
													href="https://www.facebook.com/Dartscafe-max-osaka-%E5%BF%83%E6%96%8E%E6%A9%8B%E5%BA%97-611421035942962/?ref=br_rs"
													target="blank"
												>
													<i class="fab fa-facebook"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">DUETT</th>
									<td class="text-nowrap">
										06-6213-0815
										<br />〒542-0082&nbsp;大阪市中央区島之内2-11-12
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/3gV3m6uobZkRmMLM8"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap"></td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/04226.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item"></li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">Bar Eau-de-Vie</th>
									<td class="text-nowrap">
										06-6213-4000
										<br />〒542-0085&nbsp;大阪市中央区心斎橋筋2-3-13
										リップル宗右衛門町ビル 3F
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/agpCCq9j9CnUfB8h8"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap"></td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/04530.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a
													href="https://www.facebook.com/pages/Bar-Eau-De-Vie/345279242211086"
													target="blank"
												>
													<i class="fab fa-facebook"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">Hugglife*</th>
									<td class="text-nowrap">
										06-6940-6489
										<br />〒530-0021&nbsp;大阪市北区浮田1-2-16
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/byb7BMYG2DeY2SuZ9"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking rules-caution">
										<i class="fas fa-smoking-ban mr-1"></i
										>禁煙<br />（店外灰皿アリ）
									</td>
									<td class="text-nowrap"></td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/02361.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a href="http://hugglife.org/" target="blank">
													<i class="fas fa-home"></i
												></a>
												<a
													href="https://www.facebook.com/hugglife.org/"
													target="blank"
												>
													<i class="fab fa-facebook"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">現場ar</th>
									<td class="text-nowrap">
										06-6732-4953
										<br />〒544-0013&nbsp;大阪市生野区巽中3-14-8
										ダイヤモンドビル 1F
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/axQsLLWN71AaurJY6"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/bad9b08b208d1b18774c926eb736cb5a"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/02405.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item"></li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">KITCHEN ＆ BAR O2</th>
									<td class="text-nowrap">
										06-6697-9002
										<br />〒545-0014&nbsp;大阪市阿倍野区西田辺町2-11-6
										シャトー鶴ヶ丘1F
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/4Y3tZSDjWXUzPk7r5"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/e97f5c75c68804eb25d56fb0e5c39bac"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/01548.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a href="http://bar-o2.net/" target="blank">
													<i class="fas fa-home"></i>
												</a>
												<a
													href="https://www.facebook.com/KitchenBarO2-133205263447503/?ref=bookmark"
													target="blank"
												>
													<i class="fab fa-facebook"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">Darts Bar Famous</th>
									<td class="text-nowrap">
										06-6484-8387
										<br />〒542-0083&nbsp;大阪市中央区東心斎橋1-6-20
										東心斎橋ビル5F
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/1MhbvBLbJZPUX6KM7"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking rules-caution">
										<i class="fas fa-smoking-ban mr-1"></i
										>禁煙<br />※加熱式はフロアも可<br />（喫煙室アリ）
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/a0db81439ec8ee990d9b047a20a7ba1e"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/04546.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a
													href="https://twitter.com/famous0801/"
													target="blank"
												>
													<i class="fab fa-twitter"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">Darts House Reve</th>
									<td class="text-nowrap">
										06-6809-4747
										<br />〒534-0025&nbsp;大阪市都島区片町2-6-5-2F
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/e2PiBH7ZUPiw9sYv7"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/68fa169ffc266339a3f63593b5358cc4"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/04319.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item"></li>
										</ul>
									</td>
								</tr>
								<!--<tr>
									<th scope="row" class="text-nowrap">DARTS BAR ひだまり</th>
									<td class="text-nowrap">
										090-4908-8269
										<br />〒534-0011&nbsp;大阪府大阪市都島区高倉町1-6-20
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/NwrDrjF9z6g8gHQS7"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i
										>全面喫煙可<br />※リーグ戦日は分煙<br />（喫煙室アリ）
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/b422a52f917b16080d9b047a20a7ba1e"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap"></td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item"></li>
										</ul>
									</td>
								</tr>-->
								<tr>
									<th scope="row" class="text-nowrap">Dining BAR Glow</th>
									<td class="text-nowrap">
										06-6886-2284
										<br />〒532-0024&nbsp;大阪府大阪市淀川区十三本町3-7-46
										<a
											class="btn btn-map"
											href="https://goo.gl/maps/kA1Ex1h2A76o8JY57"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/6be3a99541d612bb0d9b047a20a7ba1e"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/02370.html"
											target="blank"
											>DARTS FAN</a
										>
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item"></li>
										</ul>
									</td>
								</tr>
								<tr>
									<th scope="row" class="text-nowrap">901-QLAY-梅田店</th>
									<td class="text-nowrap">
										06-6450-5788
										<br />〒530-0051&nbsp;大阪府大阪市北区太融寺町8-2
										エーワンビル1F
										<a
											class="btn btn-map"
											href="https://maps.app.goo.gl/VAaN8GBrtXT8pSXi7"
											target="blank"
										>
											<i class="fas fa-map-marker-alt mr-1"></i>MAP
										</a>
									</td>
									<td class="text-nowrap smoking">
										<i class="fas fa-smoking mr-1"></i>全面喫煙可
									</td>
									<td class="text-nowrap">
										<a
											class="btn btn-dl"
											href="https://search.dartslive.com/jp/shop/b01107f9591fd3bba3f63593b5358cc4"
											target="blank"
											>DARTSLIVE</a
										>
									</td>
									<td class="text-nowrap">
										<!--<a
											class="btn btn-df"
											href="https://darts-fan.com/shop/osaka/02370.html"
											target="blank"
											>DARTS FAN</a
										>-->
									</td>
									<td class="text-nowrap">
										<ul class="list-inline">
											<li class="list-inline-item">
												<a
													href="https://twitter.com/901_QLAY_UMEDA"
													target="blank"
												>
													<i class="fab fa-twitter"></i>
												</a>
											</li>
										</ul>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SubHeader from "@/components/common/SubHeader.vue";

export default {
	name: "venue",
	components: {
		SubHeader,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
	padding: 4rem 1rem;
	text-align: left;
}
.btn-map {
	font-size: 0.6rem;
	margin-left: 1rem;
}
.btn-dl {
	font-size: 0.5rem;
	background-color: #efefef;
}
.btn-df {
	font-size: 0.6rem;
	background-color: #333;
	color: #fff;
}
.list-inline-item {
	i {
		font-size: 1.2rem;
		margin-right: 0.6rem;
	}
}
.rules-caution {
	i {
		color: #ffab00;
	}
}
.smoking {
	font-size: 0.7rem;
}
</style>
